import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
//import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import ReactSelect from 'react-select';
import JobCard from '../component/JobCard';
import Comp_JobCardNew from '../component/Comp_JobCardNew';

import Comp_Ejobee_Categories from '../component/comp_ejobee_categories';
import Comp_Ejobee_Section_Introvideo from '../component/comp_ejobee_section_introvideo';
import Comp_Ejobee_Section_Resume from '../component/comp_ejobee_section_resume';
import Comp_Ejobee_Worldmap from '../component/comp_ejobee_worldmap';
import Comp_Ejobee_Vacancies from '../component/comp_ejobee_vacancies';
import Comp_Testimonials from '../component/comp_testimonials';

import { faqdata, jobsdata, testimonialcarddata,TestimonialsData } from '../../JsonData';
import { Helmet } from 'react-helmet';
import { alljobrole_service, getallcityservice, latestpublishedjob_service } from '../../ApiServices';
import {Comp_Image_Slider} from '../component/comp_image_slider';
import imgBanner from '../../c_assets/images/banner_1.jpg';

import faqImg from '../../c_assets/home/faq_home_page.jpg';
import aboutUsImg from '../../c_assets/home/about_us_.png';



window.jQuery = $;
window.$ = $;
global.jQuery = $;
let jQuery=$;

const Home = () => {

    const navigate = useNavigate();

    const [faqactiveIndex, setfaqActiveIndex] = useState(null);

    const [jobcityitems, setjobcityitems] = useState([]);
    const [jobtitleitems, setjobtitleitems] = useState([]);
    const [listExpYear,setListExpYear]=useState([]);

    const [jobtitleSelect, setjobtitleSelect] = useState();
    const [jobcitySelect, setjobcitySelect] = useState();
    const [jobExpYearSelect,setJobExpYearSelect]=useState();

    const [latestjobs, setlatestjobs] = useState([]);


    const [blogs, setblogs] = useState([]);


    useEffect(() => {
        AOS.init({ 
            offset: 120,  // offset (in px) from the original trigger point
  duration: 1000,  // values from 0 to 3000, with step 50ms
  easing: 'ease',  // default easing for AOS animations
  delay: 100,  // delay (in ms) after the element enters the viewport
  //once: true,  // whether animation should happen only once - while scrolling down
  mirror: false,  // whether elements should animate out while scrolling past them
         })
        latestpublishedjob_service().then((res) => {
            setlatestjobs(res.data.data);
        }).catch((error) => {

        })
     
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
  

    }, [])

    useEffect(() => {
        getjobroles();
        getjobcities();
        getListExpYear();
    }, [])

   const getListExpYear=()=>{
        let map=[];
        let lbName="";
        for(let k=0;k<=30;k++){
            if(k===0){
                lbName="Fresher (less then 1 year)";
            }else{
                lbName=k===1?k+" Year":k+" Years";
            }
            map.push({id:k,name:lbName});
        }
        setListExpYear(map);
    }

    const getjobroles = () => {
        alljobrole_service().then((data) => {
            //setjobtitleitems(data.data.data)
        }).catch((error) => {
        })
    }

    const getjobcities = () => {
        getallcityservice().then((data) => {
            setjobcityitems(data.data)

        

        }).catch((error) => {
        })
    }



    const faqhandleButtonClick = (index) => {
        setfaqActiveIndex(index === faqactiveIndex ? null : index);
    };



    const onJobtitleSearch = (e) => {
        setjobtitleSelect(e);
    }

    const onJobtitleSelect = (e) => {
        setjobtitleSelect(e.name);
    }

    const onJobcitySelect = (e) => {
        setjobcitySelect(e.name)
    }

    const onJobcitySearch = (e) => {
        setjobcitySelect(e)
    }

    const onExpYearSelect=(e)=>{
        setJobExpYearSelect(e.id);
        //alert(JSON.stringify(e));
    }

    const onSearchbtnClick = (e) => {
        // setsearchParams({ jobtitle: jobtitleSelect, city: jobcitySelect });
        if (jobtitleSelect || jobcitySelect) {
            const searchParams = {};
            if (jobtitleSelect) {
                searchParams.jobtitle = jobtitleSelect;
            }
            if (jobcitySelect) {
                searchParams.city = jobcitySelect;
            }
            if(jobExpYearSelect){
                searchParams.exp_yrs=jobExpYearSelect;
            }
            navigate({
                pathname: '/jobs',
                search: new URLSearchParams(searchParams).toString()
            });
        } else {
            navigate({
                pathname: '/jobs'
            })
        }
    }

    const [activeCard, setActiveCard] = useState(0);

    const handleCardMouseOver = (id) => {
        setActiveCard(id);
    };
    
    const styleSelect = {
        control: base => ({
          ...base,
          border: 0,
          // This line disable the blue border
          boxShadow: 'none'
        })
      };

    return (
        <>
            <Helmet>
                <title>Find Your Dream Job on eJobee | EJOBEE</title>
                <meta name="description"
                    content="eJobee offers a wide range of job opportunities from top companies. Search and apply for your dream job today. Join thousands of successful professionals who found their career growth with eJobee." />
            </Helmet>
            {/* hero section */}
            <section className='pb-0 hero-section full-screen-header'>
            <img style={{"display":"none"}} src={imgBanner} />
                <div   className="container">
                    <div  className="row">
                        <div   className="col-lg-12 d-flex11 div-search ">
                            <div data-aos111="fade-up"   className="hero-text  d-flex flex-column justify-content-center h11-100 gap-2">
                                
                                {/* <Link to={"/resume_sample"} className='mb-0' style={{ width: 'fit-content', textDecoration: 'underline' }}>Make Your Free Resume</Link> */}
                                {/* <h1 class="center">Find your job now..<br />
                                <span>FIND HERE</span>
                                </h1> */}
                                <div className="search-bar d-flex flex-row gap-2 align-items-center">
                                    <div className="job-title-input">
                                        <ReactSearchAutocomplete items={jobtitleitems} placeholder='Skills,Job Type,City' showIcon={false} onSearch={onJobtitleSearch} onSelect={onJobtitleSelect} styling={{ border: 'none', boxShadow: 'none', zIndex: '999',padding:"26px" }} />
                                    </div>
                                    <div className='divider'></div>
                                    <div className="job-city-input">
                                        {/* <ReactSearchAutocomplete items={jobcityitems} placeholder='Job City' showIcon={false} onSearch={onJobcitySearch} onSelect={onJobcitySelect} styling={{ borderRadius: '0', border: 'none', boxShadow: 'none', zIndex: '999' }} /> */}
                                        <ReactSelect 
                                        styles={styleSelect}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => btoa(option.id)}
                                        options={listExpYear} placeholder='Select experience' showIcon={false} onChange={onExpYearSelect} style={{ borderRadius: '0', border: 'none', boxShadow: 'none', zIndex: '999',"border-style":"none" }} />
                                    </div>
                                    <div onClick={onSearchbtnClick} className='search-btn btn btn-primary' style={{ flexBasis: '15%', borderRadius: '24px' }}>
                                        Search
                                    </div>
                                </div>

                               
                            </div>
                           
                        </div>
                        <Comp_Image_Slider  lsData={[{"g_title":"Medicine","title":"testing","col1":"Custom Build","col4":"img_f/job_banner.jpeg",
"e_id":"125","col7":"../products/bath-body"},
{"g_title":"Blod Test12","title":"testing12","col1":"Replacement Parts","col4":"img_f/global_img_slider.jpeg",
    "e_id":"125","col7":"../products/digestion-stomach-care"},
    {"g_title":"Blod Test12","title":"testing12","col1":"Deals & Discount","col4":"img_f/intro_video_banner.jpeg",
        "e_id":"125","col7":"products/offers"},
        {"g_title":"Blod Test12","title":"testing12","col1":"Replacement Parts","col4":"img_f/resume_banner.jpeg",
            "e_id":"125","col7":"../products/digestion-stomach-care"},
            {"g_title":"Blod Test12","title":"testing12","col1":"Replacement Parts","col4":"img_f/recurter_anner.jpeg",
                "e_id":"125","col7":"../products/digestion-stomach-care"}
    ]
} />
                        {/* <div className="col-lg-4">
                            <img className='w-100' src="/assets/images/hero-section.png" alt="EJOBEE" />
                        </div> */}
                    </div>
                </div>
            </section>
            {/* List of Company Type */}
            {/* <Comp_Ejobee_Vacancies/> */}

            {/* job categories */}
            <Comp_Ejobee_Categories />

            {/* Intro Video */}
            <Comp_Ejobee_Section_Introvideo/>

            {/* Make your resume */}
            <Comp_Ejobee_Section_Resume/>

            {/* Latest jobs Section */}
            {
                latestjobs.length != 0 ? <section>
                    <div data-aos="fade-up" className="container">
                        <div className="section-title">
                            <h4>Latest Jobs</h4>
                            <Link to={'/jobs'}>View All</Link>
                        </div>
                        <div className="row mt-4 job-row">
                            {
                                latestjobs.slice(0, 6).map((v) => {
                                    return (
                                        // <Comp_JobCardNew/>
                                        <JobCard jobtitle={v.jobrole_name} companyname={v.company.companyname} location={v.city_name} minsalary={v.min_salary} maxsalary={v.max_salary} worktype={v.work_type.name} employementtype={v.job_type.name} slug={v.slug} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </section> : ""
            }

            {/* About Us Section */}
            <section style={{ background: '#f5f5f5' }} className='pb-0'>
                <div data-aos="fade-up" className="container">
                    <div className="row reverse">
                        <div className="col-lg-6">
                            <img className='w-100 img-thumb' src={aboutUsImg} alt="EJOBEE" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h11-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>About Us</h4>
                                </div>
                                <p>
                                    At eJobee, we believe in connecting talent with opportunity, fostering professional growth, and empowering individuals to build their dream careers. As a leading job portal with a cutting-edge resume maker, we are dedicated to revolutionizing the way you approach your job search and career development.
                                </p>
                                <Link to={'/about-us'} className='btn btn-secondary'>Know More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

          
            {
                localStorage.getItem('candidate_googleuid') ?
                    <section>
                        <div data-aos="fade-up" className="container">
                            <div className="section-title">
                                <h4>Jobs For You</h4>
                                <Link to={'/jobs'}>View All</Link>
                            </div>
                            <div className="row mt-4 job-row">
                                {
                                    jobsdata.slice(0, 6).map((v) => {
                                        return (
                                            <JobCard jobtitle={v.jobTitle} companyname={v.companyName} companyprofilepic={v.companyProfilePic} location={v.location} minsalary={v.minSalary} maxsalary={v.maxSalary} worktype={"work from Home"} employementtype={v.employmentType} slug={v.slug} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section> :
                    ''
            }

            <Comp_Testimonials lsData={TestimonialsData} />

            {/* testimonail */}
           

            {/* blogs */}
            {
                blogs.length != 0 ?
                    <section>
                        <div className="container">
                            <div className="section-title mb-3">
                                <h4>Blogs</h4>
                            </div>
                            <div className="row" style={{ rowGap: '10px' }}>
                                {
                                    blogs.slice(0, 3).map((b) => {
                                        return (
                                            <Link to={`/blogs/${b.slug}`} className="col-lg-4">
                                                <div class="blog">
                                                    <div class="blog-img">
                                                        <img src={b.image} alt="" />
                                                    </div>
                                                    <div class="blog-content">
                                                        <h2 class="blog-title">{b.title}</h2>
                                                        <p class="blog-desc">{b.shortDescription}</p>
                                                        <div class="blog-details">
                                                            <div class="blog-author-img">
                                                                <img className='rounded-circle' src={b.postedProfilePic} alt="" />
                                                            </div>
                                                            <div class="blog-author-details">
                                                                <h4 class="blog-author-name">{b.postedBy}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section> : ""
            }

            {/* faq */}
            <section style={{ paddingBottom: '0px', background: '#f5f5f5' }}>
                <div className="container">
                    <div className="section-title mb-3">
                        <h4>FAQ's</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mt-auto">
                            <div class='faq-container pb-5'>
                                {
                                    faqdata.map((v, i) => {
                                        return (
                                            <div className={`faq ${i === faqactiveIndex ? 'active' : ''}`}>
                                                <h3 class="faq-title">{v.question}</h3>
                                                <p class="faq-text" style={{ whiteSpace: 'pre-line' }}>{v.answer}</p>
                                                <button onClick={() => faqhandleButtonClick(i)} class='faq-toggle'>
                                                    <svg class="chevron w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
                                                    </svg>

                                                    <svg class="close w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                    </svg>
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className='w-100 img-thumb' src={faqImg} alt="EJOBEE" />
                        </div>
                    </div>
                </div>
            </section>

              {/* resume Section */}
              <section className='bg-color resume-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="resume-box d-flex flex-column align-items-center gap-4">
                                <h2>We’re here to help!</h2>
                                <p>Whether you have a question, feedback, or need assistance, feel free to reach out. Our team is available and ready to assist you.</p>
                                <Link to={'/contact-us'} className='btn btn-primary resume-btn'>Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* matching Job Section */}
            {/* <Comp_Ejobee_Worldmap/> */}
        </>
    )
}

export default Home