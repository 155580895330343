import './comp_ejobee_section_introvideo.css'

const Comp_Ejobee_Section_Introvideo=(props)=>{
    return(
        <div class="container-fluid pt-100 pb-100  position-relative half-fluid introvideodiv">
    <div class="container">
    <h2 class="vc_custom_heading ico_header">Intro Video with your Profile</h2>
    <hr/>
      <div class="row ">
        
        <div class="col-lg-6 col-md-6 col-sm-12 mt-auto">
          <p><strong>Make a Lasting Impression: </strong>
            In a world where first impressions matter, a well-crafted intro video sets you apart. It’s your opportunity to leave a memorable mark that a traditional resume alone can’t achieve.</p>
          <p><strong>Step into the Spotlight: </strong>
            Your career is a journey—make sure you start it with a video that truly represents who you are. Whether you’re applying for internships, jobs, or showcasing your portfolio, your intro video is your chance to shine.</p>
            <p><strong>Elevate Your Job Applications: </strong>
                Add a dynamic edge to your job applications with an intro video that speaks directly to employers. Let them see the person behind the resume, and communicate your enthusiasm, confidence, and creativity.</p>
            <p><strong>Showcase Your Unique Identity: </strong>
                An intro video is more than just a clip—it’s your chance to present your personality, skills, and aspirations to the world. It’s where your story comes to life, allowing you to connect with potential employers on a deeper level.</p>
        </div>

         
         <div class="col-lg-6 col-md-6 col-sm-12 pb-5">
            <div class="image "> <img src="assets/images/941.png" alt=""/></div>
          </div>
      </div>
    </div>
  </div>
    );
}
export default Comp_Ejobee_Section_Introvideo;