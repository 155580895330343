import $ from 'jquery';
import React, { useEffect, useRef,useState } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import {defaultLoad} from '../../functional_lib/common_lib';

const Navbar = ({menuclick,firstname,lastname}) => {
    const navigate = useNavigate();
    const [listExpYear,setListExpYear]=useState([]);
    //const [jobExpYearSelect,setJobExpYearSelect]=useState();

    const [jobtitleSelect, setjobtitleSelect] = useState();
    const [jobcitySelect, setjobcitySelect] = useState();
    const [jobExpYearSelect,setJobExpYearSelect]=useState();

    const isAuthenticated = localStorage.getItem('candidate_id');

    const navBarRef = useRef(null);

    const checkTest=localStorage.getItem("admin-token");

    const removeTest=()=>{
        localStorage.removeItem("candidate_id");
        localStorage.removeItem("candidate_is_profile_completed");
        localStorage.removeItem("candidate_is_education_details");
        localStorage.removeItem("candidate_is_personal_details");
        localStorage.removeItem("candidate_is_experience_details");
        localStorage.removeItem("candidate_is_preference_details");
      }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                // Use current property of the ref to access the DOM element
                navBarRef.current.classList.add('floatingNav');
            } else {
                navBarRef.current.classList.remove('floatingNav');
            }
        };

        window.addEventListener('scroll', handleScroll);
        defaultLoad();
        getListExpYear();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [navBarRef])

// search param logics
    const styleSelect = {
        control: base => ({
          ...base,
          border: 0,
          // This line disable the blue border
          boxShadow: 'none'
        })
      };

      const getListExpYear=()=>{
        let map=[];
        let lbName="";
        for(let k=0;k<=30;k++){
            if(k===0){
                lbName="Fresher (less then 1 year)";
            }else{
                lbName=k===1?k+" Year":k+" Years";
            }
            map.push({id:k,name:lbName});
        }
        setListExpYear(map);
    }

    const onJobtitleSelect = (e) => {
        setjobtitleSelect(e.target.value);
    }

    const onExpYearSelect=(e)=>{
        setJobExpYearSelect(e.id);
    }
    const onSearchbtnClick = () => {
        // setsearchParams({ jobtitle: jobtitleSelect, city: jobcitySelect });
        
        if (jobtitleSelect || jobcitySelect || jobExpYearSelect) {
            const searchParams = {};
            if (jobtitleSelect) {
                searchParams.jobtitle = jobtitleSelect;
            }
            if (jobcitySelect) {
                searchParams.city = jobcitySelect;
            }
            if(jobExpYearSelect){
                searchParams.exp_yrs=jobExpYearSelect;
            }
            navigate({
                pathname: '/jobs',
                search: new URLSearchParams(searchParams).toString()
            });
        } else {
            navigate({
                pathname: '/jobs'
            })
        }
    }
//End of Search Param City

    return (
        <nav ref={navBarRef}>
            <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="nav-left">
                        <div onClick={menuclick} className="menu-icon">
                            <div className="menu-line"></div>
                            <div className="menu-line"></div>
                            <div className="menu-line"></div>
                        </div>
                        
                        <Link to={''} className="logo">
                            <img className='w-100' src="/assets/logo/logo.png" alt="EJOBEE" />
                        </Link>

                        <a href="javascript:void(0)" className="header-action-btn search-btn-mob search-btn search-icon mt-2">
                        <i class="fa fa-search fa-2x search-btn11" aria-hidden="true"></i>
                            </a>
                                <div className="dropdown_search">
                                    <div class="input-group mb-2 col">
                                    <input id="nav_txtSearc" name="nav_txtSearch" onChange={onJobtitleSelect} className="form-control" placeholder="Skills,Job Type,City" type="text" />
                                    </div>
                                    <div className="input-group mb-2 d-flex">
                                    <ReactSelect 
                                    className={"expyear-col"}
                                        styles={styleSelect}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => btoa(option.id)}
                                        options={listExpYear} placeholder='Select experience' showIcon={false} onSelect={onExpYearSelect} style={{ borderRadius: '0', border: 'none', boxShadow: 'none', zIndex: '999',"border-style":"none" }} />
                                        <button onClick={onSearchbtnClick} className="submit col search-btn btn btn-primary" type="button">
                                    Search
                                        </button>
                                    </div>
                                    <div style={{"float":"right"}} class="input-group">
                                    

                                    </div>
                               
                                </div>
                        {/* <i class="fa fa-search fa-2x search-btn" aria-hidden="true"></i> */}
                       
                    </div>
                    <div className="d-flex gap-5">
                        <ul className='gap-3 m-0 align-items-center'>
                            <li>
                                <Link to={'/'}>Home</Link>
                            </li>
                            {/* <li>
                                <Link to={'/'}>Login</Link>
                            </li> */}
                            <li>
                                <Link to={'/about-us'}>About Us</Link>
                            </li>
                            <li>
                                <Link to={'/jobs'}>Jobs</Link>
                            </li>
                            {/* <li>
                                <Link to={'/resume_sample'}>Resume</Link>
                            </li> */}
                            {/* <li>
                                <Link to={'/premium'}>Premium</Link>
                            </li> */}
                            <li>
                                <Link to={'/blogs'}>blogs</Link>
                            </li>
                            <li>
                                <Link to={'/contact-us'}>Contact Us</Link>
                            </li>
                        </ul>
                        {
                            isAuthenticated ? <li>
                                <div className="dropdown">
                                    <div className='fw-bold mt-auto dropdown-toggle mb-0' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" style={{ color: 'var(--primary-color)' }}>
                                        <i class="far fa-user-circle green-color  fa-2x ml-2 mr-2"></i>,Hello
                                        {/* Hello,<i class="fas fa-bars"></i> */}
                                    {/* {firstname} */}
                                    
                                    </div>
                                    <ul className='dropdown-menu mt-2' aria-labelledby="dropdownMenuButton1">
                                    {/* admin back */}
                                    {checkTest?<li><Link class="dropdown-item" onClick={removeTest} to={'/admin/candidate'}>Back to Admin</Link></li>:''}
                                    <li><Link class="dropdown-item" to={'/dashboard'}>Dashboard</Link></li>
                                        <li><Link class="dropdown-item" to={'/profile'}>Profile</Link></li>
                                        {/* <li><Link class="dropdown-item" to={'/notification'}>Notifications</Link></li> */}
                                        {/* <li><Link class="dropdown-item" to={'/saved-jobs'}>Saved Jobs</Link></li>
                                        <li><Link class="dropdown-item" to={'/applied-jobs'}>Applied Jobs</Link></li> */}
                                        <li>
                                            <div class="dropdown-item text-danger" data-bs-toggle="modal" style={{cursor:'pointer'}} data-bs-target="#logoutdialogue">Logout</div>
                                        </li>
                                    </ul>
                                </div>
                            </li> : <Link to={'/login'} className="btn btn-primary login_btn ms-3">
                                Login
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar