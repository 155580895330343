import $ from 'jquery';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useLocation, useNavigate } from 'react-router-dom'
import { candidate_personal_detail, getcityservice, getcountryservice, getstateservice } from '../../ApiServices'

const Register = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [countries, setcountries] = useState([]);
    const [states, setstates] = useState([]);
    const [cities, setcities] = useState([]);


    const getcountry = () => {
        getcountryservice().then((data) => {
            const mappedoptions = data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            setcountries(mappedoptions)
        }).catch((error) => {
            getcountry()
        })
    }


    const getstate = (countryid) => {
        getstateservice(countryid).then((data) => {
            const mappedoptions = data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            setstates(mappedoptions)
        }).catch((error) => {
            getstate();
        })
    }


    const getcities = (stateid) => {
        getcityservice(stateid).then((data) => {
            const mappedoptions = data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            setcities(mappedoptions)
        }).catch((error) => {
            getcities();
        })
    }

    useEffect(() => {
        const id = new URLSearchParams(location.search).get('id');
        if (id == '' || id == null || !localStorage.getItem('candidate_id')) {
            navigate('/login');
        }

        if (localStorage.getItem('candidate_id')==null) {
            navigate('/login');
        }

        if (localStorage.getItem('candidate_is_personal_details')=='true') {
            navigate('/');
        }
        getcountry();
    }, [])


    const [formData, setformData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        gender: "male",
        dob: "",
        country: "",
        state: "",
        city_name: ""
    })

    const [errors, seterrors] = useState({})


    const handleChange = (event) => {
        const { name, value } = event.target
        seterrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setformData({
            ...formData,
            [name]: value
        })
    }

    const handleSelectChange = (data, action) => {
        seterrors((prevErrors) => ({
            ...prevErrors,
            [action.name]: '',
        }));
        setformData({
            ...formData,
            [action.name]: data
        })
        if (action.name == "country") {
            getstate(data.value);
        }

        if (action.name == "state") {
            getcities(data.value);
        }
    }

    const validateform = () => {
        const newErrors = {};

        if (!formData.firstname) {
            newErrors.firstname = 'First Name is required';
        }

        if (!formData.lastname) {
            newErrors.lastname = 'Last Name is required';
        }

        // Validate email
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is Not Valid';
        }

        if (!formData.dob) {
            newErrors.dob = 'Date of Birth is Required';
        }

        if (!formData.country) {
            newErrors.country = 'country is Required';
        }

        if (!formData.state) {
            newErrors.state = 'State is Required';
        }

        // if (!formData.city) {
        //     newErrors.city = 'City is Required';
        // }
        if(!formData.city_name){
            newErrors.city_name="City Required";
        }

        seterrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    }

    const continuebtnclick = () => {
        if (validateform()) {
            candidate_personal_detail(formData).then((data) => {
                
                //$("#respcheck").html(JSON.stringify(data));
                if(typeof data.data!=="undefined"){
                    localStorage.setItem('candidate_is_personal_details','true');
                    navigate('/')
                }else{
                    alert("Mail-Id already exists, kindly use different mailid");
                }
                
            }).catch((error) => {

            })
        }
    }


    return (
        <div className='main-login'>
            <div className="container">
                <div id="respcheck"></div>
                <div className="row">
                    <div className="col-lg-7">
                        <img className='w-100' src="/assets/images/personal-detail.png" alt="EJOBEE" />
                    </div>
                    <div className="col-lg-5">
                        <div className="login-box d-flex flex-column justify-content-center h-100">
                            <h2>Personal Details</h2>
                            <p>Fill Your Personal Details And Continue to Next Step For Success</p>
                            <div className="login-form mt-2">
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <input className='form-control' type="text" placeholder='FirstName' name='firstname' onChange={handleChange} />
                                        {errors.firstname && <span className="text-danger">{errors.firstname}</span>}
                                    </div>
                                    <div className="col-lg-6">
                                        <input className='form-control' type="text" placeholder='lastName' name='lastname' onChange={handleChange} />
                                        {errors.lastname && <span className="text-danger">{errors.lastname}</span>}
                                    </div>
                                </div>
                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <input type="text" placeholder='Email' className="form-control" name='email' onChange={handleChange} />
                                        {errors.email && <span className="text-danger">{errors.email}</span>}
                                    </div>
                                </div>
                                <div className="form-group row mt-3 mb-3">
                                    <div className="col-lg-12 d-flex gap-4 align-items-center">
                                        <label htmlFor="">Gender</label>
                                        <div className='d-flex gap-3 align-items-center justify-content-center'>
                                            <label className='d-flex gap-1 align-items-center justify-content-center'>
                                                <input
                                                    type="radio"
                                                    value="male"
                                                    name="gender"
                                                    checked={formData.gender === 'male'}
                                                    onChange={handleChange}
                                                />
                                                Male
                                            </label>
                                            <label className='d-flex gap-1 align-items-center justify-content-center'>
                                                <input
                                                    type="radio"
                                                    value="female"
                                                    name="gender"
                                                    checked={formData.gender === 'female'}
                                                    onChange={handleChange}
                                                />
                                                Female
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <input type="date" placeholder='Date Of Birth' className="form-control" name='dob' onChange={handleChange} />
                                        {errors.dob && <span className="text-danger">{errors.dob}</span>}
                                    </div>
                                </div>
                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="country"
                                            placeholder={'Select country'}
                                            value={formData.country}
                                            onChange={handleSelectChange}
                                            options={countries}
                                        />
                                        {errors.country && <span className="text-danger">{errors.country}</span>}
                                    </div>
                                </div>
                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="state"
                                            placeholder="Select State"
                                            value={formData.state}
                                            onChange={handleSelectChange}
                                            options={states}
                                        />
                                        {errors.state && <span className="text-danger">{errors.state}</span>}
                                    </div>
                                </div>
                                <div className="form-group row mt-2">
                                    <div className="col-lg-12">
                                    <input
                    type="text"
                    name='city_name'
                    value={formData.city_name}
                    class="form-control"
                    placeholder="Full address with city"
                    onChange={handleChange}
                  />
                                    {/*
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder={'Select City'}
                                            isSearchable={true}
                                            name="city"
                                            value={formData.city}
                                            onChange={handleSelectChange}
                                            options={cities}
                                        />*/}
                                        {errors.city_name && <span className="text-danger">{errors.city_name}</span>}
                                    </div>
                                </div>
                                <div onClick={continuebtnclick} className='btn btn-primary mt-3 w-100'>Continue</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register