import React,{useEffect,useState} from 'react';
import {setResumeTemplateData,bindEdit} from '../../functional_lib/common_lib';
import { public_url } from '../../ApiUrl';

export function Comp_Resm_3(props){
  const [resumeData,setResumeData]=useState({});
  useEffect(()=>{
    if(typeof props.setProfile.candidate_master!=="undefined"){
        setResumeData(setResumeTemplateData(props.setProfile));
        setTimeout(() => {
          bindEdit();
        }, 1000);
      }
  },[props.setProfile])

    return(<div class="fourthtemplate">
      {typeof resumeData.basic!=="undefined"?
        <div class="resume">
          <div class="resume_left">
            <div class="resume_profile">
              {typeof resumeData.basic.profile_pic!=="undefined"?
              <img src={public_url+resumeData.basic.profile_pic} alt="profile_pic" />:''
              }
              
            </div>
            <div class="resume_content">
              <div class="resume_item resume_info">
                <div class="title">
                  <p class="bold">{resumeData.basic.first_name} {resumeData.basic.last_name}</p>
                  <p class="regular editable" e_type="text" e_key="f" e_id="ft">Designer</p>
                </div>
                <ul>
                <li>
                    <div class="icon">
                      <i class="fa fa-weebly"></i>
                    </div>
                    <div class="data">
                      DOB:{resumeData.basic["dob"]}
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-map-signs"></i>
                    </div>
                    <div class="data">
                      {resumeData.contact.address}
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fas fa-mobile-alt"></i>
                    </div>
                    <div class="data">
                      {resumeData.contact.mobile}
                    </div>
                  </li>
                  <li>
                  {/* <i class="fas fa-envelope"></i> {resumeData.contact.email} */}
                    <div class="data">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i> {resumeData.contact.email}
                    </div>
                  </li>
                  
                </ul>
              </div>
              
              <div class="resume_item resume_skills">
                <div class="title">
                  <p class="bold">languages</p>
                </div>
                <ul>
                <li>
                <div class="skill_name11 white">
                  {resumeData.language.map((item,index)=>{
                    return<> {index!==0?", "+item:item}</>
                  })}
                   </div>
                    {/* <div class="skill_progress">
                      <span style={{"width":"80%"}}></span>
                    </div> */}
                    {/* <div class="skill_per">80%</div> */}
                  </li>
                </ul>
              </div>
            </div>
         </div>
         <div class="resume_right">
           <div class="resume_item resume_about">
               <div class="title">
                  <p class="bold">About Me</p>
                </div>
               <p class="editable" e_type="textarea" e_key="f" e_id="ft">I am passionate about staying ahead of design trends and continuously improving my skills to deliver high-quality work. My experience spans a range of projects, from designing responsive websites and mobile apps to developing comprehensive brand identities and marketing materials.</p>
           </div>
           <div class="resume_item resume_work">
               <div class="title">
                  <p class="bold">Work Experience</p>
                </div>
               <ul>
                {resumeData.experience.map((item,index)=>{
                  return <li>
                  <div class="date">{item["duration"]}</div> 
                  <div class="info">
                       <p class="semi-bold">{item["jobrole_name"]}</p> 
                    <p>{item["company_name"]}</p>
                  </div>
              </li>

                })}
               </ul>
           </div>
           <div class="resume_item resume_education">
             <div class="title">
                  <p class="bold">Education</p>
                </div>
             <ul>
              {resumeData.education.map((item,index)=>{
                return <li>
                <div class="date">{item["duration"]}</div> 
                <div class="info">
                     <p class="semi-bold">{item["degree"]}({item["university"]})</p> 
                  <p>UX Fundamentals, User Research, Prototyping and Testing, Interaction Design, UX Strategy</p>
                </div>
            </li>
              })}
               </ul>
           </div>
           <div class="resume_item resume_hobby11">
             <div class="title">
                  <p class="bold">Skills</p>
                </div>
              <ul>
              <li class="editable" e_type="textarea" e_key="f" e_id="ft">
                {resumeData.skills.map((item,index)=>{
                  return <>{index!==0?", "+item:item}</>
                })}
              </li>
                
                {/* <li><i class="fas fa-gamepad"></i></li>
                <li><i class="fas fa-music"></i></li>
                <li><i class="fab fa-pagelines"></i></li> */}
             </ul>
           </div>
           <div class="resume_item resume_hobby11">
             <div class="title">
                  <p class="bold">Preference</p>
                </div>
              <ul>
              {resumeData.preference.map((item,index)=>{
                return<>
               <li>
                    <div class="icon">
                      <i class="fa fa-facebook-square"></i>
                    </div>
                    <div class="data">
                      <p><span class="semi-bold"> Work: </span>{item["work"]}</p>
                    </div>
                  </li>
                <li>
                    <div class="icon">
                      <i class="fa fa-facebook-square"></i>
                    </div>
                    <div class="data">
                      <p><span class="semi-bold">Timing: </span>  {item["job_time"]}</p>
                      <p></p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-facebook-square"></i>
                    </div>
                    <div class="data">
                      <p ><span class="semi-bold">Job Shift: </span>{item["job_shift"]}</p>
                      <p></p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-facebook-square"></i>
                    </div>
                    <div class="data">
                      <p><span class="semi-bold">Pref. City: </span> {item["city_name"]}</p>
                      <p></p>
                    </div>
                  </li>
                </>
              })}
                
              
             </ul>
           </div>
         </div>
       </div>
       :''}
      </div>)
}
export default Comp_Resm_3;